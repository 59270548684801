import React, { Component } from 'react'

class ResourceSubscriber extends Component {

    constructor(props) {
        super(props)
        this.state = {
            awaitingResponseFromServer: true
        }
    }

    async subscribeToResource() {

        let data = {
            resourceLocation: window.location.href
        }
        
        let response = await fetch(`${window.location.protocol}//${process.env.REACT_APP_API_ADDRESS ?? 'localhost:3001'}/subscribeToResource`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(data)
        })

        return response
    
    }

    getMessageFromURL() {

        const URL = window.location.href
        let slug = URL.split('/').pop()

        // English = 0, German = 1. Defaults to English
        let index = 0

        let responses = {
            '1': ['English 1', 'German 1'],
            '2': ['English 2', 'German 2'],
            '3': ['English 3', 'German 3'],
        }

        // Sets to 1 if German cookie is set, otherwise 0 (English)
        if (document.cookie.match(/language=German/g)) {
            index = 1
        } else {
            index = 0
        }

        // If there is a defined response then return it, otherwise return an empty string.
        if ((responses[slug] !== undefined) && (responses[slug][index])) {
            return responses[slug][index]
        } else {
            return ''
        }


    }

    async componentDidMount() {
        let response = await this.subscribeToResource()
        console.log(response.status)
        this.setState({ awaitingResponseFromServer: false })
    }

    render() {
        return (
            <div className='content'>
                {this.state.awaitingResponseFromServer
                    ? <h1><span lang='en'>Waiting</span><span lang='de'>Abwartend</span>...</h1>
                    : <div className='resourceSubscribed'>
                        <div lang='en' className='closeThePage'>Your document will be emailed to you shortly.<br/>
You can now close this web page or click the image below to view your document immediately.</div>
                        <div lang='de' className='closeThePage'>Ihr Dokument wird Ihnen in Kürze per E-Mail zugesendet.<br/>
Sie können diese Webseite jetzt schließen oder auf das Bild unten klicken, um Ihr Dokument sofort anzuzeigen.</div>
                        <a href={`/flyers/${window.location.pathname.split('/')[1]}.pdf`}>
                            <img src={`/flyer_covers/${window.location.pathname.split('/')[1]}.jpg`} alt='Document cover'/>
                        </a>
                    </div>
                }
            </div>
        )
    }
}

export default ResourceSubscriber