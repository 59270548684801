import { useForm } from 'react-hook-form'

const requestCookie = (checkCookieState) => async (data) => {

    await fetch(`${window.location.protocol}//${process.env.REACT_APP_API_ADDRESS ?? 'localhost:3001'}/getCookie`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    })

    checkCookieState()

}

export const Login = ({checkCookieState}) => {

    const { register, handleSubmit } = useForm()

    return (
        <div className='content'>
            <div className='instructions'>
                <div lang='en' className='instructionsheader'>
                    Download the latest AMI information directly to your inbox.
                </div>
                <div lang='de' className='instructionsheader'>
                Laden Sie die neuesten AMI-Informationen direkt in Ihren Posteingang herunter.
                </div>
                <div lang='en' className='instructionsbody'>
                    <p>Simply:</p>
                    <ol>
                        <li>Enter your email address (you only need to do this once)</li>
                        <li>Use you cell phone to scan any event or portfolio you would like to learn more about</li>
                        <li>Receive documents directly to your inbox</li>
                    </ol>
                </div>
                <div lang='de' className='instructionsbody'>
                    <p>Einfach:</p>
                    <ol>
                        <li>Geben Sie Ihre E-Mail-Adresse ein (Sie müssen dies nur einmal tun)</li>
                        <li>Verwenden Sie Ihr Mobiltelefon, um jede Veranstaltung zu scannen, über die Sie mehr erfahren möchten</li>
                        <li>Erhalten Sie Dokumente direkt in Ihren Posteingang</li>
                    </ol>
                </div>
            </div>
            <div className='email'>
                <div lang='en' className='notice'>
                    You <strong>must</strong> use the email address provided on registration to the expo.
                </div>
                <div lang='de' className='notice'>
                    Sie müssen die E-Mail-Adresse verwenden, die bei der Registrierung an den EXPOS bereitgestellt wird.
                </div>
                <form className='emailinput' onSubmit={handleSubmit( data => requestCookie(checkCookieState)(data))}>
                    {/* <input type='string' {...register('name')} placeholder='Enter your name' required />
                    <input type='string' {...register('company')} placeholder='Enter your company name' required />
                    <input type='string' {...register('job_title')} placeholder='Enter your job title' required /> */}
                    <input type='email'  {...register('email_address')} placeholder='Enter your email address' required />
                    {/* <input type='string' {...register('telephone_number')} placeholder='Enter your telephone number' />
                    <input type='string' {...register('country')} placeholder='Enter your country' required /> */}
                    <span>
                        <input type='checkbox' id='consent' name='content' required /> <label>I agree and consent to the <a href='https://www.amiplastics.com/about/legal'>privacy policy</a></label>
                    </span>
                    <input type='submit' lang='en' className='emailinput' name='submit'/>
                </form>
                <div lang='en' className='information'>
                    <p>Your documents will be sent to you today.</p>
                    <p>If they have not appeared in your inbox, please check your junk folder.</p>
                    <p>If you have any issues or would like to speak to an AMI representative visit us at booth A123 or contact <a href="mailto:eventsales@amiplastics.com">eventsales@amiplastics.com</a></p>
                </div>
                <div lang='de' className='information'>
                    <p>Ihre Dokumente werden Ihnen heute an Sie gesendet.</p>
                    <p>Wenn sie nicht in Ihrem Posteingang angezeigt werden, überprüfen Sie bitte Ihren Junk-Ordner.</p>
                    <p>Wenn Sie Probleme haben oder mit einem AMI-Vertreter sprechen möchten, besuchen Sie uns am Stand C205 oder wenden Sie sich an uns unter <a href="mailto:eventsales@amiplastics.com">eventsales@amiplastics.com</a></p>
                </div>
            </div>
        </div>
    )

}
