import './App.scss'
import React, { Component } from 'react'
import { Login } from './LogIn.js'
import ResourceSubscriber from './ResourceSubscriber.js'


class App extends Component {

	//The single page application for the QR code front end.
	//Everything happens on this component.
	//If the user does not have a cookie, the components are unmounted and the log in component is mounted.
	//If the user does have a cookie, the components are mounted and the log in component is not mounted.
	
	constructor(props) {

		super(props)

		this.state = {
			loggedIn: false,
			language: 'English'
		}

		this.checkCookieState = this.checkCookieState.bind(this)
		this.toggleLanguage = this.toggleLanguage.bind(this)
		this.deleteEmailCookie = this.deleteEmailCookie.bind(this)

	}

	checkCookieState() {

		//Check if user has cookie.
		if (document.cookie.match(/(AMI_EXPO_QR=)[0-9]*/g)) {
			//Has cookie
			this.setState({ loggedIn: true })
		} else {
			//Does not have cookie
			this.setState({ loggedIn: false })
		}

		if (document.cookie.match(/language=German/g)) {
			this.setState({ language: 'German' })
		} else {
			this.setState({ language: 'English' })
		}
	}


	toggleLanguage() {
		if (this.state.language === 'English') {
			this.setState({ language: 'German' })
			document.cookie = 'language=German'
		} else {
			this.setState({ language: 'English' })
			document.cookie = 'language=English'
		}
	}

	deleteEmailCookie() {
		document.cookie = 'AMI_EXPO_QR=; domain=ami-global.info; expires=Thu, 01-Jan-70 00:00:01 GMT;'
        document.cookie = 'AMI_EXPO_QR=; domain=qr.amiplastics.com; expires=Thu, 01-Jan-70 00:00:01 GMT;'
        document.cookie = 'AMI_EXPO_QR=; domain=qr-test.amiplastics.com; expires=Thu, 01-Jan-70 00:00:01 GMT;'
		document.cookie = 'AMI_EXPO_QR=; domain=localhost; expires=Thu, 01-Jan-70 00:00:01 GMT;'
		this.setState({ loggedIn: false })
	}

	componentDidMount = async () => {
		this.checkCookieState()
	}

	render() {

		return (
			<div className={`App ${this.state.language} ${this.state.loggedIn ? 'logged_in' : 'logged_out'}`}>
				<div className='logos' />
				<div className='welcomebar'>
					<h1 lang='en'>Welcome</h1>
					<h1 lang='de'>Willkommen</h1>
					{this.state.loggedIn
						?
							<div>
								<button lang='en' onClick={this.deleteEmailCookie}>
									Use a different email
								</button>
								<button lang='de' onClick={this.deleteEmailCookie}>
									Verwenden Sie eine andere E-Mail
								</button>
							</div>
						:
							null
					}
					<div className='instructionsbutton'>
                        <button lang='en' className='languagetoggle' onClick={this.toggleLanguage}>
							Wechsel zu Deutsch
                        </button>
                        <button lang='de' className='languagetoggle' onClick={this.toggleLanguage}>
                            change to English
                        </button>
                    </div>
				</div>
				{this.state.loggedIn
					? <ResourceSubscriber
						toggleLanguage={this.toggleLanguage}
					/>
					: <Login
						checkCookieState={this.checkCookieState.bind(this)}
						toggleLanguage={this.toggleLanguage}
						{...this.state}
					/>
				}
				
				<div className='footer'>
					<div className='contactus'>
						<ul>
							<li>
								T<span>/</span><a href='tel:+441179249442'>+44 (0) 117 924 9442</a>
							</li>
							<li>
								T<span>/</span><a href='tel:+16104780800'>+1 610 478 0800</a>
							</li>
							<li>
								E<span>/</span><a href='mailto:eventsales@amiplastics.com'>eventsales@amiplastics.com</a>
							</li>
							<li>
								<a href='https://www.amiplastics.com/'>www.amiplastics.com</a>
							</li>
						</ul>
					</div>
					<div lang='en' className='legal'>
						<a href='https://www.amiplastics.com/about/legal'>Privacy policy</a>&nbsp;/&nbsp;<a href='https://www.amiplastics.com/about/tac'>Terms of use</a><br/>
						Registered in England, No: 2140318<br/>
						Registered in Pennsylvania (PA) USA, No: 3010392<br/>
						© Applied Market Information Ltd
					</div>
					<div lang='de' className='legal'>
						<a href='https://www.amiplastics.com/about/legal'>Datenschutz-Bestimmungen</a>&nbsp;/&nbsp;<a href='https://www.amiplastics.com/about/tac'>Nutzungsbedingungen</a><br/>
						In England eingetragen No: 2140318<br/>
						© Applied Market Information Ltd
					</div>
				</div>

			</div>
		)



	}
}

export default App
